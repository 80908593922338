export const WORDS = [
  'react',
  'https',
  'input',
  'babel',
  'fetch',
  'redux',
  'flask',
  'swift',
  'cloud',
  'azure',
  'shell',
  'agile',
  'scrum',
  'stack',
  'scala',
  'debug',
  'error',
  'linux',
  'proxy',
  'route',
  'cache',
  'crash',
  'pixel',
  'pages',
  'mysql',
  'regex',
  'oauth',
  'kafka',
  'redis',
  'mongo',
  'maven',
  'vuejs',
  'macos',
  'email',
  'axios',
  'field',
]
